import environment from "../../environment";

const URLS = {
    // BALROG URLs
    registerUser: `${environment.BALROG_MAIN_URL}/v1.0/authentication/register?inviteId=:inviteId`,
    loginUser: `${environment.BALROG_MAIN_URL}/v1.0/authentication/login/password`,
    forgotPassword: `${environment.BALROG_MAIN_URL}/v1.0/authentication/forgot-password`,
    resetPassword: `${environment.BALROG_MAIN_URL}/v1.0/authentication/set-password`,
    verifyEmail: `${environment.BALROG_MAIN_URL}/v1.0/authentication/register/complete`,
    googleLogin: `${environment.BALROG_MAIN_URL}/v1.0/authentication/login/google`,
    logoutUser: `${environment.BALROG_MAIN_URL}/v1.0/authentication/logout`,
    currentUserSession: `${environment.BALROG_MAIN_URL}/v1.0/session`,
    allUserSessions: `${environment.BALROG_MAIN_URL}/v1.0/session/list`,
    profileDetails: `${environment.BALROG_MAIN_URL}/v1.0/profile/detail`,
    updateEmailInit: `${environment.BALROG_MAIN_URL}/v1.0/profile/update-email-init`,
    updateEmailComplete: `${environment.BALROG_MAIN_URL}/v1.0/profile/update-email-complete`,
    updatePassword: `${environment.BALROG_MAIN_URL}/v1.0/profile/update-password`,
    searchUsers: `${environment.BALROG_MAIN_URL}/v1.0/org/:orgId/search?email=:email`,
    searchUsersByIds: `${environment.BALROG_MAIN_URL}/v1.0/org/:orgId/search?id=:ids`,
    googleOAuthLogin: `${environment.BALROG_MAIN_URL}/v1.0/authentication/google`,
    updateMeta: `${environment.BALROG_MAIN_URL}/v1.0/profile/meta`,
    getUsersByIds: `${environment.BALROG_MAIN_URL}/v1.0/profiles`,

    // MITHRANDIR URLs
    createOrganization: `${environment.MITHRANDIR_MAIN_URL}/v1.0/`,
    getOrganizationDetails: `${environment.MITHRANDIR_MAIN_URL}/v1.0/`,
    getOrganizationDetailsById: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/detail`,
    getOrganizationDetailsByIds: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/orgs/details`,
    updateOrganizationDetailsById: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/`,
    getOrganizationPreference: `${environment.MITHRANDIR_MAIN_URL}/v1.0/`,
    getOrganizationsForUser: `${environment.MITHRANDIR_MAIN_URL}/v1.0/all`,
    createApp: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/apps`,
    getApps: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/apps?page=:page&pageSize=:pageSize&name=:name`,
    getAppDetails: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/apps/:appId`,
    updateApp: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/apps/:appId`,
    deleteApp: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/apps/:appId`,
    createTeamMember: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/team`,
    addUserInOrgAndUserGroup: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/team/add`,
    acceptInvite: `${environment.MITHRANDIR_MAIN_URL}/v1.0/accept-invite/:inviteId`,
    getInvitationList: `${environment.MITHRANDIR_MAIN_URL}/v1.0/invite?status=:status`,
    editUserGroupMapping: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/user-groups-map/`,
    getTeam: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/team/?userId=:userId&page=:page&pageSize=:pageSize`,
    getAllTeams: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/team/all`,
    getTeamMember: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/team/:memberId`,
    updateTeamMember: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/team/:memberId`,
    deleteTeamMember: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/team/:memberId`,
    createUserGroup: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/user_group/`,
    updateUserGroup: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/user_group/:userGroupId`,
    deleteUserGroup: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/user_group/:userGroupId`,
    getUserGroups: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/user-groups/list/?page=:page&pageSize=:pageSize`,
    getSellerToVendorMappingList: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:sellerId/vender-seller-map/list?page=:page&pageSize=:pageSize&search=:searchText`,
    deleteVendorMapping: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:sellerId/vender-seller-map/:mappingId`,
    activateDeactivateVendorMapping: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:sellerId/vender-seller-map/:mappingId?active=:status`,
    orgListing: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/list`,
    createSellerToVendorMapping: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:sellerId/vender-seller-map`,

    // Invite
    sendInviteLink: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/invite/send`,
    getInviteDetails: `${environment.MITHRANDIR_MAIN_URL}/v1.0/invite/:inviteId`,
    getInviteListing: `${environment.MITHRANDIR_MAIN_URL}/v1.0/invite/`,
    getInviteListByOrgId: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/invite/members`,
    deleteInvite: `${environment.MITHRANDIR_MAIN_URL}/v1.0/org/:orgId/invite/:inviteId`,
    deleteInviteForUser: `${environment.MITHRANDIR_MAIN_URL}/v1.0/invite/:inviteId`,

    // NENYA_MAIN_URL
    getDataModelTags: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/utils/tags/:entity?page=:page&limit=:limit&search=:search`,
    /** Attributes */
    getAttributes: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/attribute?page=:page&limit=:limit&count=:count`,
    createAttribute: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/attribute`,
    updateAttribute: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/attribute/:attributeId`,
    deleteAttribute: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/attribute/:attributeId`,
    getAttributeById: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/attribute/:attributeId`,
    getAttributeTags: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/attribute/tags`,
    /** Categories */
    getCategories: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/category?page=:page&limit=:limit`,
    createCategory: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/category`,
    updateCategory: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/category/:categoryId`,
    deleteCategory: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/category/:categoryId`,
    getCategoryById: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/category/:categoryId`,
    /** Category Mappings */
    getCategoryMappings: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/category/mapping?page=:page&limit=:limit`,
    createCategoryMapping: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/category/mapping`,
    updateCategoryMapping: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/category/mapping/:mappingId`,
    deleteCategoryMapping: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/category/mapping/:mappingId`,
    getCategoryMappingById: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/category/mapping/:mappingId`,
    getCategoryMappingByCode: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/category/mapping/code/:code`,
    getMappingByTemplateId: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/category/mapping/template?template=:templateId&page=:page&limit=:limit`,
    getSkuMappings: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/:product_type/category_mapping`,
    /** Templates */
    getTemplates: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/template?page=:page&limit=:limit`,
    getTemplatesBulk: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/template/bulk`,
    createTemplate: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/template`,
    updateTemplate: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/template/:templateId`,
    deleteTemplate: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/template/:templateId`,
    getTemplateById: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/template/:templateId`,
    getTemplateByMappingCodes: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/category/mapping/template-details`,
    mapCategory: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/category/mapping/map/template`,
    unmapCategory: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/category/mapping/unmap/template`,
    templateValidationSchema: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:jobId/template/:id/validation`,
    plpTemplateValidationSchema: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/template/:id/validation`,
    getAttachmentsArray: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/utils/attachment/types`,
    templateAjv: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/template/validation/:templateId`,
    /** Bulk Actions */
    dataModelImport: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/bulk/import`,
    dataModelImportHistory: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/bulk/import/history?entity=:entity`,
    dataModelExport: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/bulk/export`,
    dataModelExportHistory: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/bulk/export/history?entity=:entity`,

    /** Task */
    createTask: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job`,
    updateTask: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:jobId`,
    taskListing: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/list?page=:page&limit=:limit`,
    getTaskById: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:taskId`,
    taskSplitById: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:taskId/split`,
    getComments: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:taskId/comment`,
    createComment: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:taskId/comment`,
    archiveTask: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:taskId/archive`,
    recallTask: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:taskId/recall`,
    acceptTask: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:taskId/acceptance/Accept`,
    rejectTask: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:taskId/acceptance/Reject`,
    taskFeasibility: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/feasibility`,
    getTemplateByMappingCodesForTask: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:jobId/category-mapping/template-details`,

    /** TaskWorkflow */
    getTaskworkFlowDetails: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/workflow?task=:task`,

    /** Batch */
    getBatchById: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:jobId/batch/:batchId?page=:page&limit=:limit`,
    getBatchStats: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:jobId/batch/:batchId/sku/stats`,
    changeBatchAssigneById: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/batch/:batchId/reassign`,

    /** Relationship */
    createRelationship: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/relationship`,
    getRelationships: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/relationship?page=:page&limit=:limit&name=:name&type=:type`,
    getRelationshipByIds: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/relationship/list`,
    updateRelationship: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/relationship/:id`,
    deleteRelationship: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/relationship/:id`,

    /** Relationship Mapping */
    upsertRelationshipMapping: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/relationship/mapping`,
    upsertRelationshipMappingBulk: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/relationship/mapping/bulk`,
    exportRelationship: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/relationship/mapping/export`,
    getRelationshipMappingBulkHistory: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/relationship/mapping/bulk/history?page=:page&limit=:limit&type=:type&action=:action`,
    getRelationshipMappings: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/relationship/mapping/:sku`,
    deleteRelationshipMapping: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/relationship/mapping/:sku?hash=:hash&parent=:parent&rel_id=:rel_id`,

    /** Audit Log */
    getAllLogs: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/changelog/:env/:sku`,
    getLogDetails: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/changelog/:env/:sku/:id`,

    /** Global CA Template */
    getGlobalTemplate: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/template/global`,

    /** Sales Tree URL */
    getSalesTree: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/sales/tree?page=:page&limit=:limit`,

    /** FIle list */
    getFileList: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/utils/attachment/types?type=:type`,

    // VALAR_MAIN_URL
    createProducts: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/:productType`,
    getProducts: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/:productType/list?page=:page&limit=:limit`,
    customView: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/view`,
    getProductsWithFilter: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/:env?filter_by=:filterBy&filter_value=:filterValue`,
    getJobProductsBySKU: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:jobId/product/:productSku`,
    updateProduct: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/:type?sku=:sku`,
    updateProductAsDraft: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/draft?sku=:sku`,
    postAssetJob: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/asset/upload`,
    getAssetHistory: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/asset/history/:jobId/:batchId?page=:page&limit=:limit`,
    getAssetAttributes: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/asset/attributes/:jobId?count=false&type=Media&type=File&all=true`,
    getBatchExportFile: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/bulk/schema/batch/:batchId`,
    getSkuExportFile: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/:type/bulk/export`,
    getSkuExportHistory: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/:type/bulk/export/history`,
    getBatchImportExportHistory: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/bulk/history/file/batch/:batchId/action/:action`,
    getBatchImportExportHistory2: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/bulk/history/update/batch/:batchId`,
    getTemplatesForBatch: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/bulk/templates/batch/:batchId`,
    createQuery: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:taskId/query`,
    getQueryAndComments: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:taskId/sku/:sku/query`,
    resolveQuery: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:taskId/query/resolve`,
    createQueryComment: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:taskId/sku/:sku/query/discuss`,
    syncToProduction: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/syncMaster`,
    bulkApproveRejectSKU: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/job/:taskId/batch/:batchId/audit/:status`,
    // archiveProducts: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/:productType/archive`,
    deleteProducts: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/:action/delete`,
    restoreProducts: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/restore`,
    uploadBatchBulkImportFile: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/bulk/upload`,
    getProductLabels: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/enrich/labels?page=:page&limit=:limit&search=:search`,

    initiatePlpBulkImport: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/bulk/plp/import`,
    plpSampleDownload: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/bulk/schema/sample/template/:templateId`,
    plpHistory: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/bulk/histtory/file/plp/action/:actionId`,
    retriggerWebhook: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/retrigger/subscriber/:subscriberId`,
    retriggerDataModelWebhook: `${environment.NENYA_MAIN_URL}/v1.0/org/:orgId/utils/webhook/retrigger`,
    // VILYA
    cdnUploadUrl: `${environment.VILYA_PLTM_URL}/v1.0/org/:orgId/namespaces/:namespace/upload/:status`, // status can be 'start' or 'complete'

    /** Analytics */
    getAnalytics: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/dashboard`,

    // RINGIL
    /** Brick Code */
    getBrickCodeListing: `${environment.RINGIL_MAIN_URL}/v1.0/org/:orgId/catalog/brickcodes`,

    /** Inbounds */
    InboundListing: `${environment.RINGIL_MAIN_URL}/v1.0/org/:orgId/inbound?page=:page&limit=:limit&app_id=:app_id&q=:searchQuery&active=:active`,
    createInbound: `${environment.RINGIL_MAIN_URL}/v1.0/org/:orgId/inbound`,
    getInboundById: `${environment.RINGIL_MAIN_URL}/v1.0/org/:orgId/inbound/:id`,
    updateInbound: `${environment.RINGIL_MAIN_URL}/v1.0/org/:orgId/inbound/:id`,
    deleteInbound: `${environment.RINGIL_MAIN_URL}/v1.0/org/:orgId/inbound/:id`,
    getInboundSkuData: `${environment.RINGIL_MAIN_URL}/v1.0/org/:orgId/product/inbound/:id`,
    getInboundSkuMetaData: `${environment.RINGIL_MAIN_URL}/v1.0/org/:orgId/product/inbound/:id/meta/:logId`,
    mapInboundSkuCategory: `${environment.RINGIL_MAIN_URL}/v1.0/org/:orgId/product/inbound/:id/category-mappings`,
    exportInboundLogs: `${environment.RINGIL_MAIN_URL}/v1.0/org/:orgId/inbound/:id/bulk/export/external-product`,
    getInboundLogsExportHistory: `${environment.RINGIL_MAIN_URL}/v1.0/org/:orgId/inbound/:id/bulk/export/external-product/history`,

    // Palantir
    getWebhooks: `${environment.PALANTIR_PLTM_URL}/v1.0/org/:orgId/subscriber`,
    getWebhook: `${environment.PALANTIR_PLTM_URL}/v1.0/org/:orgId/subscriber/:subscriberId`,
    getWebhookLogs: `${environment.PALANTIR_PLTM_URL}/v1.0/org/:orgId/subscriber/:subscriberId/report?page_no=:page&page_size=:limit&range=:range&event_name=:event_name&version=:version&event_type=:event_type&status=:status&sort=:sort&q=:searchText&platform_status=:platform_status`,
    getWebhookLogPayloadById: `${environment.PALANTIR_PLTM_URL}/v1.0/org/:orgId/subscriber/:subscriberId/report/:eventId`,
    createWebhook: `${environment.PALANTIR_PLTM_URL}/v1.0/org/:orgId/subscriber`,
    updateWebhook: `${environment.PALANTIR_PLTM_URL}/v1.0/org/:orgId/subscriber`,
    deleteWebhook: `${environment.PALANTIR_PLTM_URL}/v1.0/org/:orgId/subscriber/:subscriberId`,
    getEvents: `${environment.PALANTIR_PLTM_URL}/v1.0/org/:orgId/events?limit=:limit`,
    TEST_WEBHOOK: `${environment.PALANTIR_PLTM_URL}/v1.0/org/:orgId/subscriber/ping`,

    // Mappers
    createMapping: `${environment.PALANTIR_PLTM_URL}/v1.0/org/:orgId/mapper`,
    updateMapping: `${environment.PALANTIR_PLTM_URL}/v1.0/org/:orgId/mapper/:id`,
    getMappings: `${environment.PALANTIR_PLTM_URL}/v1.0/org/:orgId/mapper?name=:name&page=:page&limit=:limit`,
    getMapping: `${environment.PALANTIR_PLTM_URL}/v1.0/org/:orgId/mapper/:id`,
    testMapping: `${environment.PALANTIR_PLTM_URL}/v1.0/org/:orgId/mapper/execute`,

    // Rule Engine
    getProductsWithRuleFilter: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/products/filter?query=:query&page=:page&limit=:limit&q=:search`,
    verifySku: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/rule/verify/selection`,
    testRule: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/rule/test`,
    getTestResultByRunId: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/rule/test/:runId`,
    getRules: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/rule?page=:page&limit=:limit&q=:search&all=:all&status=:status`,
    getRule: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/rule/:id`,
    createRule: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/rule`,
    updateRule: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/rule/:id`,
    executeRule: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/rule/:id/execute`,
    deleteRule: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/rule/:id`,
    getRuleLogs: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/rule/:id/logs?page=:page&limit=:limit&q=:search&start_date=:start_date&end_date=:end_date&status=:status`,
    getRuleLogPayload: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/rule/:id/logs/:logId`,
    getRuleVersions: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/rule/:id/version?page=:page&limit=:limit`,
    getRuleVersion: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/rule/:id/version/:versionId`,

    // Integrations
    connectRedirect: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/integration/:type`,
    getIntegrations: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/integration?page=:page&limit=:limit&type=:type&all=:all`,
    getIntegrationItems: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/integration/:type/:id/list?page=:page&limit=:limit&status=:status&name=:name&source=:source`,
    setDefaultIntegration: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/integration/:type/default/:id`,
    getIntegrationOrgs: `${environment.VALAR_MAIN_URL}/v1.0/org/:orgId/integration/:type/:id/orgs`,
};

export default URLS;
